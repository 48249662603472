import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { H3, H5, IconSearch, ModalInMobile, NamedLink, Page } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: false,
      isMobileModalOpen: false,
      keyword: '',
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
    };

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  handleKeywordChange = e => {
    const keyword = e.target.value;
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.setState({ keyword });
    }, 200);
  };

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};
      const dropNonFilterParams = false;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig, dropNonFilterParams),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig
    );

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      config,
      history,
    } = this.props;

    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      defaultFiltersConfig,
      false
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    const availablePrimaryFilters = [...customPrimaryFilters, ...defaultFilters];
    const availableFilters = [
      ...customPrimaryFilters,
      ...defaultFilters,
      ...customSecondaryFilters,
    ];
    console.log(availableFilters);
    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, customSecondaryFilters, [])
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { bounds, origin } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>

    const isAppliances = urlQueryParams?.pub_category === 'appliances';
    const isGames = urlQueryParams?.pub_category === 'apps-and-games';
    const isBaby = urlQueryParams?.pub_category === 'baby';
    const isBooks = urlQueryParams?.pub_category === 'books';

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.layoutWrapperContainer}>
          <div className={css.container}>
            <div className={css.searchSection}>
              <div className={css.searchLeft}>
                <h1>Search your keywords</h1>
              </div>
              <div className={css.searchRight}>
                <div className={css.searchBox}>
                  <input type="text" placeholder="Search..." onChange={this.handleKeywordChange} />
                  <button
                    type="button"
                    onClick={() =>
                      history.push(
                        createResourceLocatorString(
                          'SearchPage',
                          routeConfiguration,
                          {},
                          { keywords: this.state.keyword }
                        )
                      )
                    }
                  >
                    <IconSearch />
                  </button>
                </div>
              </div>
            </div>
            <div className={css.searchCategorySection}>
              <div className={css.categorySorting}>
                <MainPanelHeader
                  className={css.mainPanel}
                  sortByComponent={sortBy('desktop')}
                  isSortByActive={sortConfig.active}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  noResultsInfo={noResultsInfo}
                />
              </div>
              <div className={css.categoryTabs}>
                <NamedLink
                  name="SearchPage"
                  className={classNames(css.tab, {
                    [css.active]: !isAppliances && !isGames && !isBaby && !isBooks,
                  })}
                >
                  All Items
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: `pub_category=appliances` }}
                  className={classNames(css.tab, {
                    [css.active]: isAppliances,
                  })}
                >
                  Appliances
                  {/* <FormattedMessage id="SearchPage.art" /> */}
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: `pub_category=apps-and-games` }}
                  className={classNames(css.tab, {
                    [css.active]: isGames,
                  })}
                >
                  Apps & Games
                  {/* <FormattedMessage id="SearchPage.game" /> */}
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: `pub_category=baby` }}
                  className={classNames(css.tab, {
                    [css.active]: isBaby,
                  })}
                >
                  Baby
                  {/* <FormattedMessage id="SearchPage.photography" /> */}
                </NamedLink>
                <NamedLink
                  name="SearchPage"
                  to={{ search: `pub_category=books` }}
                  className={classNames(css.tab, {
                    [css.active]: isBooks,
                  })}
                >
                  Books
                  {/* <FormattedMessage id="SearchPage.photography" /> */}
                </NamedLink>
                {/* <NamedLink name="SearchPage" className={css.tab}>
                <FormattedMessage id="SearchPage.music" />
              </NamedLink>
              <NamedLink name="SearchPage" className={css.tab}>
                <FormattedMessage id="SearchPage.video" />
              </NamedLink> */}
              </div>
            </div>
            <div className={css.mainWrapper}>
              <aside className={css.layoutWrapperFilterColumn}>
                <div className={css.filterColumnContent}>
                  {availablePrimaryFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersPrimary.${config.key}`}
                        idPrefix="SearchFiltersPrimary"
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      />
                    );
                  })}
                  <button
                    className={css.resetAllButton}
                    onClick={() => {
                      this.setState({ currentQueryParams: {} });
                      history.push(
                        createResourceLocatorString('SearchPage', routeConfiguration, {}, {})
                      );
                    }}
                  >
                    <span className={css.cross}>
                      <svg class="" width="24" height="24" viewBox="0 0 16 16" fill="inherit">
                        <path d="M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm1.529 4.195L8 7.057 6.471 5.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943L7.057 8 5.528 9.528c-.26.26-.26.682 0 .943s.682.26.943 0L8 8.942l1.529 1.529c.26.26.682.26.943 0s.26-.682 0-.943L8.942 8l1.529-1.529c.26-.26.26-.682 0-.943s-.682-.26-.943 0z"></path>
                      </svg>
                    </span>{' '}
                    <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                  </button>
                </div>
              </aside>

              <div className={css.layoutWrapperMain} role="main">
                <div className={css.searchResultContainer}>
                  <SearchFiltersMobile
                    className={css.searchFiltersMobileMap}
                    urlQueryParams={validQueryParams}
                    sortByComponent={sortBy('mobile')}
                    listingsAreLoaded={listingsAreLoaded}
                    resultsCount={totalItems}
                    searchInProgress={searchInProgress}
                    searchListingsError={searchListingsError}
                    showAsModalMaxWidth={MODAL_BREAKPOINT}
                    onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onOpenModal={this.onOpenMobileModal}
                    onCloseModal={this.onCloseMobileModal}
                    resetAll={this.resetAll}
                    selectedFiltersCount={selectedFiltersCountForMobile}
                    noResultsInfo={noResultsInfo}
                    isMapVariant
                  >
                    {availableFilters.map(config => {
                      return (
                        <FilterComponent
                          key={`SearchFiltersMobile.${config.key}`}
                          idPrefix="SearchFiltersMobile"
                          config={config}
                          marketplaceCurrency={marketplaceCurrency}
                          urlQueryParams={validQueryParams}
                          initialValues={initialValues(this.props, this.state.currentQueryParams)}
                          getHandleChangedValueFn={this.getHandleChangedValueFn}
                          intl={intl}
                          liveEdit
                          showAsPopup={false}
                        />
                      );
                    })}
                  </SearchFiltersMobile>

                  <div className={css.searchResultSummary}>
                    <span className={css.resultsFound}>
                      {searchInProgress ? (
                        <FormattedMessage id="MainPanelHeader.loadingResults" />
                      ) : (
                        <FormattedMessage
                          id="MainPanelHeader.foundResults"
                          values={{ count: totalItems }}
                        />
                      )}
                    </span>
                  </div>
                  <div
                    className={classNames(css.listings, {
                      [css.newSearchInProgress]: !listingsAreLoaded,
                    })}
                  >
                    {searchListingsError ? (
                      <h2 className={css.error}>
                        <FormattedMessage id="SearchPage.searchError" />
                      </h2>
                    ) : null}
                    <SearchResultsPanel
                      className={css.searchListingsPanel}
                      listings={listings}
                      pagination={listingsAreLoaded ? pagination : null}
                      search={parse(location.search)}
                      setActiveListing={onActivateListing}
                      isMapVariant
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
